/* eslint-disable eqeqeq */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
import logger from './logger';

export const getCountryFromTmtID = (tmtId) => {
  if (typeof tmtId === 'undefined') {
    return tmtId;
  }

  if (!tmtId) {
    return 'us';
  }

  let countryName;

  const countryID = parseInt(`${tmtId}`[0], 10);

  switch (countryID) {
    case 0:
    case 1:
    case 2:
      countryName = 'us';
      break;
    case 3:
      countryName = 'ca';
      break;
    case 4:
      countryName = 'ie';
      break;
    case 5:
      countryName = 'uk';
      break;
    default:
      countryName = 'us';
  }

  return countryName;
};

// Hexadecimal Operations.
export const hex2Bin = (hex) => {
  if (!hex) {
    return null;
  }
  const bytes = [];
  for (let i = 0; i < hex.length - 1; i += 2) {
    bytes.push(parseInt(hex.substr(i, 2), 16));
  }
  // eslint-disable-next-line prefer-spread
  return String.fromCharCode.apply(String, bytes);
};

export const decodeQueryParam = (param) =>
  typeof atob === 'undefined'
    ? Buffer.from(hex2Bin(param), 'base64').toString()
    : // eslint-disable-next-line no-undef
      atob(hex2Bin(param));

export default decodeQueryParam;

export const IS_MAINTENANCE = false;

const stage = (import.meta.env.PIPELINE || '').replace(/^cp/, '');

export const isProd = stage === 'master';

export const getTokenFromLocation = () =>
  window.location.pathname.split('/')[3];

export const parseQueryString = (queryString) => {
  const query = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  // eslint-disable-next-line no-plusplus, @typescript-eslint/prefer-for-of
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

// eslint-disable-next-line no-undef
export const locationInURL = parseQueryString(window.location.search).location;

export const gtmLoad = () => {
  // eslint-disable-next-line func-names
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}&gtm_auth=${import.meta.env.REACT_APP_GTM_AUTH}&gtm_preview=${import.meta.env.REACT_APP_GTM_PREVIEW}&gtm_cookies_win=x`;
    if (typeof f === 'undefined') {
      return;
    }
    f.parentNode.insertBefore(j, f);
    // eslint-disable-next-line no-undef
  })(window, document, 'script', 'dataLayer', 'GTM-TM9VLZR');
};

export const gtmEvent = (event, data) => {
  const eventData = data
    ? {
        event,
        ...data,
      }
    : {
        event,
      };
  logger.info('gtmEvent', eventData);
  // eslint-disable-next-line no-undef
  window.dataLayer.push(eventData);
};

// Hexadecimal Operations.
// const hex2Bin = (hex) => {
//   if (!hex) {
//     return null
//   }
//   var bytes = []
//   for(var i=0; i< hex.length-1; i+=2){
//     bytes.push(parseInt(hex.substr(i, 2), 16))
//   }
//   // eslint-disable-next-line prefer-spread
//   return String.fromCharCode.apply(String, bytes)
// }

// export const decodeQueryParam = param => {
//   try {
//     if (!param) {
//       throw new Error('Param is empty');
//     }
//     return atob(hex2Bin(param));
//   } catch (error) {
//     return '';
//   }
// };

// eslint-disable-next-line no-undef
export const uflDomain =
  window.location.host.indexOf('movetrac') > -1
    ? 'https://quote.twomenandatruck.com'
    : 'https://ufl-main.twomenbeta.com';

// export const getCountryFromTmtID = tmtId => {
//   if (typeof tmtId === 'undefined') {
//     return tmtId;
//   }

//   if (!tmtId) {
//     return 'us';
//   }

//   let countryName;

//   const countryID = parseInt(tmtId[0], 10);

//   switch (countryID) {
//     case 0:
//     case 1:
//     case 2:
//       countryName = 'us';
//       break;
//     case 3:
//       countryName = 'ca';
//       break;
//     case 4:
//       countryName = 'ie';
//       break;
//     case 5:
//       countryName = 'uk';
//       break;
//     default:
//       countryName = 'us';
//   }

//   return countryName;
// };

export const countryExtension = (tmtId) =>
  getCountryFromTmtID(tmtId) === 'us' ? 'com' : getCountryFromTmtID(tmtId);

/** @returns {Object.<string, string>}} */
export const stateValues = (country) =>
  country === 'us'
    ? {
        AL: 'Alabama',
        AK: 'Alaska',
        AS: 'American Samoa',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District Of Columbia',
        FM: 'Federated States Of Micronesia',
        FL: 'Florida',
        GA: 'Georgia',
        GU: 'Guam',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MH: 'Marshall Islands',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        MP: 'Northern Mariana Islands',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PW: 'Palau',
        PA: 'Pennsylvania',
        PR: 'Puerto Rico',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VI: 'Virgin Islands',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
      }
    : {
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NS: 'Nova Scotia',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Québec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
      };
