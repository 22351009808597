import { Trans } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

import logger from 'lib/logger.ts';
import { ReactNode } from 'react';

const Err = ({
  error,
  message = '',
}: {
  error?: string;
  message: ReactNode;
}) => {
  if (error) {
    logger.error(error);
  }
  return (
    <Alert
      severity="error"
      icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
    >
      {message}
      {!message && (
        <Trans>
          An error occurred processing your request. Please clear your cache
          (CTRL + F5) and try again.
        </Trans>
      )}
    </Alert>
  );
};

export default Err;
