import React, { lazy, Suspense } from 'react';
import Amplify from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider } from 'react-i18next';
import { Router } from '@reach/router';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

import { IS_MAINTENANCE } from 'lib/utils';
import { themeForOnboardingState } from 'theme/theme';
import logger from 'lib/logger.ts';
import i18n from './i18n';
import { gtmLoad } from 'lib/utils';
import AppContainer from './components/App/AppContainer';
import ErrorCard from './components/Error/ErrorCard';
import Maintenance from 'components/Maintenance';
import ScrollToTop from 'components/App/ScrollToTop';
import QuoteRedirector from 'components/QuoteRedirector';

const QuotePreview = lazy(() => import('./components/QuotePreview'));
const CsrAuthenticate = lazy(() => import('./components/App/CsrAuthenticate'));

Amplify.configure({
  Auth: {
    region: import.meta.env.REACT_APP_AUTH_REGION,
    userPoolId: import.meta.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

    this.state = {
      authState: props.authState || null,
      authData: props.authData || null,
    };
  }

  componentDidMount() {
    gtmLoad();
  }

  handleAuthStateChange(state, data) {
    this.setState({ authState: state, authData: data });
  }

  render() {
    const { authState } = this.state;
    logger.info(authState, 'authState');
    return (
      <Suspense fallback="">
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary FallbackComponent={ErrorCard}>
            {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              IS_MAINTENANCE && (
                <MuiThemeProvider theme={themeForOnboardingState()}>
                  <CssBaseline />
                  <Maintenance />
                </MuiThemeProvider>
              )
            }
            {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              !IS_MAINTENANCE && (
                <Router>
                  <ScrollToTop path="/">
                    <QuotePreview path="q/:encodedEstimateID/:encodedToken/*" />
                    <QuoteRedirector path="quote/:encodedEstimateID/:encodedToken/*" />
                    <CsrAuthenticate
                      path="signup"
                      handleAuthStateChange={this.handleAuthStateChange}
                    />
                    <AppContainer
                      default
                      authState={authState}
                      handleAuthStateChange={this.handleAuthStateChange}
                    />
                  </ScrollToTop>
                </Router>
              )
            }
          </ErrorBoundary>
        </I18nextProvider>
      </Suspense>
    );
  }
}

export default App;
