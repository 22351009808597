import LogRocket from 'logrocket';
import { string } from 'yup';
import { faUser } from '@fortawesome/pro-light-svg-icons';

import {
  GetAccountDocument,
  GetAccountCustomerDocument,
  GetCustomerByIdDocument,
  GetCustomersByEmailDocument,
  UpdateCustomerDocument,
} from 'graphql/compiled';
import client from 'graphql/client';

import logger from './logger';

/**
 * @typedef {import("./form").AccountFormField} AccountFormField
 */

export const getCustomersByEmail = async ({
  email,
  fetchPolicy = 'cache-first',
}) => {
  let response;

  try {
    response = await client.query({
      query: GetCustomersByEmailDocument,
      variables: { email },
      fetchPolicy,
    });
  } catch (error) {
    LogRocket.captureException(error);
    logger.error(error);
    if (
      error.graphQLErrors &&
      error.graphQLErrors.length &&
      error.graphQLErrors[0].message === 'NO_RECORD_FOUND'
    ) {
      return [];
    }

    logger.error('Error not NO_RECORD_FOUND');
    throw error;
  }

  return response.data.getCustomersByEmail;
};

export const getAccountCustomer = async () => {
  logger.info('getAccountCustomer');

  const response = await client.query({
    query: GetAccountCustomerDocument,
    fetchPolicy: 'no-cache',
  });

  return response.data?.getAccount.customer;
};

export const getCustomerById = async (customerID) => {
  logger.info('getCustomerById');

  const response = await client.query({
    variables: { customerID },
    query: GetCustomerByIdDocument,
  });

  return response.data?.getCustomerById;
};

export const updateCustomer = async (customerID, input) => {
  logger.info({ customerID, input }, 'updateCustomer');
  const response = await client.mutate({
    mutation: UpdateCustomerDocument,
    variables: { customerID, input },
  });

  const { getAccount } = await client.readQuery({
    query: GetAccountDocument,
  });

  // We updated the active customer, so write it to local cache.
  if (getAccount && customerID === getAccount.customerID) {
    await client.writeQuery({
      query: GetAccountDocument,
      data: {
        getAccount: {
          ...getAccount,
          customer: response.data.updateCustomer,
        },
      },
    });
  }

  return response.data.updateCustomer;
};

/**
 * @return {AccountFormField[]}
 */
export const getCustomerFields = (t) => [
  {
    name: 'name.first',
    label: t(`First Name`),
    icon: faUser,
    validation: string().required(t(`First Name is required.`)),
  },
  {
    name: 'name.last',
    label: t(`Last Name`),
    icon: faUser,
    validation: string().required(t(`Last Name is required.`)),
  },
  // {
  //   name: 'smsOptIn',
  //   label: t(`May we send you text messages?`),
  //   type: 'SelectBoolean',
  //   icon: faComments,
  //   helperText: (
  //     <Trans>
  //       See our{' '}
  //       <Link
  //         href="https://twomenandatruck.com/sms-terms-of-service"
  //         target="blank"
  //       >
  //         terms of service
  //       </Link>{' '}
  //       for more information.
  //     </Trans>
  //   ),
  // },
];

/**
 * Capitalizes the first letter of a customer type string.
 *
 * @param {string} customerType - The customer type string to format.
 * @return {string} The formatted customer type string.
 *
 * @example
 * formatCustomerType('individual') // 'Individual'
 */
export const formatCustomerType = (customerType) =>
  customerType.charAt(0).toUpperCase() + customerType.slice(1);
