import {
  parse,
  format,
  startOfDay,
  isSameDay,
  isAfter,
  isBefore,
  fromUnixTime,
  addDays,
  endOfWeek,
  differenceInDays,
  subDays,
  differenceInHours,
} from 'date-fns';
import type { Estimate } from './estimates';

const dateFormat = 'yyyy-MM-dd';

/**
 * Format a date as a string.
 *
 * Takes a Date object and returns a string in the format 'yyyy-MM-dd'.
 *
 * @param d - The date to format.
 * @returns The formatted date string.
 */
export const formatDate = (d: Date): string => format(d, dateFormat);

/**
 * Format a date for use with Hubspot.
 *
 * Takes a number (unix ms), string (ISO or RFC2822), or Date object and returns
 * a string in the format 'MM/dd/yyyy'.
 *
 * @param d - The date to format.
 *
 * @returns A string in the format 'MM/dd/yyyy'.
 */
export const formatHubspotDate = (d: number | string | Date): string =>
  format(new Date(d), 'MM/dd/yyyy');

export const sixtyDaysAgo = (): string => formatDate(subDays(new Date(), 60));

/**
 * Return a default range of dates for checking availability on an estimate.
 *
 * For an estimate with a preferred service date in the future, the default range
 * will be 3 days before the preferred service date to 3 days after. If the
 * preferred service date is today or in the past, the default range will be
 * 4 days from now to 11 days from now. The dates are returned as strings in the
 * format 'yyyy-MM-dd'.
 *
 * @param estimate - The estimate to calculate the default range for.
 *
 * @returns An object with `begin` and `end` properties, each a string in the
 * format 'yyyy-MM-dd' representing the start and end dates of the default
 * availability range for the given estimate.
 */
export const availabilityDefaultRange = (
  estimate: Pick<Estimate, 'preferredServiceDate'>,
): { begin: string; end: string } => {
  const moveDate = startOfDay(new Date(estimate.preferredServiceDate || ''));

  const now = new Date();

  const minDate = addDays(startOfDay(now), now.getHours() >= 20 ? 4 : 5);

  const startDate = isBefore(moveDate, minDate)
    ? minDate
    : subDays(moveDate, 3);

  return {
    begin: format(startDate, dateFormat),
    end: format(addDays(startDate, 7), dateFormat),
  };
};

/**
 * Parses a time string into a Date object.
 * @param time - The time string in 'HH:mm:ssX' format.
 * @returns The parsed Date object.
 */
export const parseEstimateDuration = (time: string): Date =>
  parse(time, 'HH:mm:ssX', new Date());

/**
 * Formats a time into a string.
 * @param time - The time as a number or Date object.
 * @returns The formatted time string.
 */
export const displayTime = (time: number | Date): string => format(time, 'p');

/**
 * The start of the current day.
 */
export const today = startOfDay(new Date());

/**
 * The end of the current week.
 */
export const thisWeek = endOfWeek(new Date());

/**
 * The date 30 days ago from today.
 */
export const thirtyDaysAgo = subDays(new Date(), 30);

/**
 * Formats a date into a string.
 * @param d - The date as a number or Date object.
 * @returns The formatted date string.
 */
export const displayDate = (d: number | Date): string => format(d, 'P');

/**
 * The date format for database storage.
 */
export const newDbDate = 'yyyy-MM-dd';

/**
 * Formats a date into a database date string.
 * @param d - The date as a number or Date object.
 * @returns The formatted database date string.
 */
export const displayDbDate = (d: number | Date): string => format(d, newDbDate);

/**
 * The current date formatted for database storage.
 */
export const todayDbDate = format(startOfDay(new Date()), newDbDate);

/**
 * Converts a Unix timestamp to a Date object.
 * @param unix - The Unix timestamp.
 * @returns The Date object.
 */
export const dateFromUnix = (unix: number): Date => fromUnixTime(unix);

/**
 * Parses a database date string into a Date object.
 * @param db - The database date string.
 * @returns The parsed Date object.
 */
export const dateFromDB = (db: string): Date =>
  parse(db, newDbDate, new Date());

/**
 * Checks if a date is recent (after today).
 * @param d - The date as a number or Date object.
 * @returns True if the date is recent, false otherwise.
 */
export const dateIsRecent = (d: number | Date): boolean => isAfter(d, today);

/**
 * Extracts the date part from a preferred service date string.
 * @param preferredServiceDate - The preferred service date string.
 * @returns The extracted date part or null.
 */
export const dbDateFromPreferredServiceDate = (
  preferredServiceDate: string | null | undefined,
): string | null =>
  preferredServiceDate ? preferredServiceDate.slice(0, 10) : null;

/**
 * Formats a preferred service date string for display.
 * @param preferredServiceDate - The preferred service date string.
 * @returns The formatted date string.
 */
export const displayPreferredServiceDate = (
  preferredServiceDate: string | null | undefined,
): string =>
  displayDate(
    parse(
      dbDateFromPreferredServiceDate(preferredServiceDate || '') || '',
      newDbDate,
      new Date(),
    ),
  );

/**
 * Calculates a todo date from a move date and an offset.
 * @param moveDate - The move date string.
 * @param dateOffset - The number of days to offset.
 * @returns The calculated Date object.
 */
export const calculateTodoDateFromOffset = (
  moveDate: string,
  dateOffset: number,
): Date => addDays(dateFromDB(moveDate), dateOffset);

/**
 * Formats a database date string into various date components.
 * @param db - The database date string.
 * @returns An object containing formatted date components.
 */
export const bookingDate = (
  db: string,
): {
  day: string;
  fullday: string;
  month: string;
  monthday: string;
  date: string;
} => {
  const date = dateFromDB(db);
  return {
    day: format(date, 'EEE'),
    fullday: format(date, 'EEEE'),
    month: format(date, 'MMMM'),
    monthday: format(date, 'do'),
    date: format(date, 'M/d'),
  };
};

/**
 * Converts a date string to an offset from a move date.
 * @param date - The date string.
 * @param moveDate - The move date string.
 * @returns The number of days offset.
 */
export const convertDateToOffset = (date: string, moveDate: string): number =>
  differenceInDays(
    parse(date, newDbDate, new Date()),
    parse(moveDate, newDbDate, new Date()),
  );

export { isBefore, isAfter, isSameDay, differenceInHours };
