import { Auth } from 'aws-amplify';
import { string } from 'yup';
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';

import logger from 'lib/logger';
import { formatBackendPhone, rawPhone } from '@backend/src/lib/phone';

/**
 * @typedef {import("./form").AccountFormField} AccountFormField
 */

export const currentAuthenticatedUser = () =>
  new Promise((resolve) => {
    Auth.currentAuthenticatedUser().then(resolve);
  });

export const userSignUp = (values) => {
  const phone = formatBackendPhone(values.phone);

  if (!values.email) {
    throw new Error('Email is required');
  }

  if (!phone) {
    throw new Error('Invalid phone');
  }

  return Auth.signUp({
    username: values.username,
    password: values.password,
    attributes: {
      email: values.email,
      phone_number: phone,
    },
  });
};

export const userSignIn = ({ username, password }) =>
  Auth.signIn(username, password);

export const handleSignOut = (e) => {
  e.stopPropagation();
  Auth.signOut()
    .then(() => window.location.reload())
    .catch((err) => logger.error(err, 'Error'));
};

/**
 * @return {AccountFormField[]}
 */
export const getUserFields = (t) => [
  {
    name: 'email',
    label: t(`Email`),
    helperText: t(`This will need to be reverified if changed.`),
    icon: faEnvelope,
    type: 'email',
    validation: string()
      .email(t(`Email needs to be a valid email.`))
      .required(t(`Email is required.`)),
    customerField: 'emailAddress',
  },
  {
    name: 'phone_number',
    label: t(`Mobile Phone`),
    helperText: t(`This will need to be reverified if changed.`),
    icon: faPhone,
    type: 'tel',
    validation: string().notRequired(),
    optional: true,
    customerField: 'phone',
    customerValue: (v) => rawPhone(v),
  },
];
