import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Trans } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// import LoginContainer from './App/Login/LoginContainer';

import Footer from './Footer/Footer';

import styles from './App/Login/LoginContainer.styles';

const useStyles = makeStyles((theme) => styles(theme));

const Container = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.loginContainer}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classes.login}
      >
        <img
          src="./img/border-top.svg"
          alt=""
          className={clsx(classes.backgroundBorder, classes.borderTop)}
        />
        <Grid
          item
          style={{ maxWidth: '768px' }}
          classes={{
            item: classes.gridItem,
          }}
        >
          <Card className={classes.card}>{children}</Card>
          <Footer
            background="dark"
            hasSidebar={false}
            style={{ marginLeft: 0 }}
          />
        </Grid>
        <img
          src="./img/border-bottom.svg"
          alt=""
          className={clsx(classes.backgroundBorder, classes.borderBottom)}
        />
      </Grid>
    </div>
  );
};

const Maintenance = () => {
  return (
    <Container>
      <Box m={4} textAlign="center">
        <Typography variant="h6">
          <Trans>
            Movetrac is currently undergoing maintenance and will be back
            shortly.
          </Trans>
        </Typography>
      </Box>
    </Container>
  );
};

export default Maintenance;
