import { useMatch, redirectTo } from '@reach/router';

import decodeQueryParam from 'lib/utils';
import { useEffect } from 'react';

const QuoteRedirector = () => {
  const match = useMatch('/quote/:encodedEstimateID/:encodedToken/*');
  const { encodedEstimateID, encodedToken } = match as unknown as {
    encodedEstimateID: string;
    encodedToken: string;
  };
  const decodedQueryParams = decodeQueryParam(encodedEstimateID).split('/');
  const decodedEstimateID = decodedQueryParams[0];

  useEffect(() => {
    if (decodedEstimateID) {
      redirectTo(`/q/${decodedEstimateID}/${encodedToken}`);
    }
  }, [decodedEstimateID, encodedToken]);

  return <></>;
};

export default QuoteRedirector;
