const isLocal =
  process.env.NODE_ENV === 'development' ||
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  import.meta?.env?.NODE_ENV === 'development';

const backendCloudFrontDomain = !isLocal
  ? 'translation.twomenandatruck.com'
  : `tmt-translation-staging.twomenbeta.com`;
const backendDomain = !isLocal
  ? '5ye97hqxbd.execute-api.us-east-2.amazonaws.com'
  : `toh0o9h4nd.execute-api.us-east-2.amazonaws.com`;

const app = 'movetrac';

const authorization = 'zxQRtmWRKNzwQE6ZvVFK';

export default {
  backendCloudFrontDomain,
  backendDomain,
  app,
  authorization,
};
