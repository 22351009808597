import {
  faBoxOpen,
  faPaw,
  faChild,
  faTruck,
} from '@fortawesome/pro-regular-svg-icons';

import {
  GetAccountDocument,
  CreateMoveDocument,
  UpdateMoveDocument,
} from 'graphql/compiled';
import client from 'graphql/client';

import logger from './logger';
import { isBefore, dateFromDB, today } from '@backend/src/lib/date';

export const createMove = async (input) => {
  const response = await client.mutate({
    mutation: CreateMoveDocument,
    variables: { input },
  });

  return response.data.createMove;
};

export const updateMove = async (input) => {
  logger.info({ input }, 'updateMove');
  const response = await client.mutate({
    mutation: UpdateMoveDocument,
    variables: { input },
  });

  const { getAccount } = client.readQuery({
    query: GetAccountDocument,
  });

  const move = input;

  // We updated the active move, so write it to local cache.
  if (move.id === getAccount.activeMove.id) {
    client.writeQuery({
      query: GetAccountDocument,
      data: {
        getAccount: {
          ...getAccount,
          activeMove: response.data.updateMove,
        },
      },
    });
  }

  return response.data.updateMove;
};

export const moveIsInPast = (moveDate) => isBefore(dateFromDB(moveDate), today);

export const moveAttributes = ({ accountType, t }) => {
  const options = {
    home: [
      {
        type: 'kids',
        label: t(`I'm moving with kids`),
        icon: faChild,
      },
      {
        type: 'pets',
        label: t(`I'm moving with pets`),
        icon: faPaw,
      },
      {
        type: 'selfPack',
        label: t(`I plan to pack myself`),
        icon: faTruck,
      },
      {
        type: 'movingCompany',
        label: t(`I plan to use a moving company`),
        icon: faBoxOpen,
      },
    ],
    business: [
      {
        type: 'selfPack',
        label: t(`I plan to pack myself`),
        icon: faTruck,
      },
      {
        type: 'movingCompany',
        label: t(`I plan to use a moving company`),
        icon: faBoxOpen,
      },
    ],
  };

  return options[accountType];
};
