import type { Maybe } from 'graphql-tools';

// import { EstimateAddress } from '@backend/src/lib/estimates';
import {
  isBefore,
  isAfter,
  dateFromDB,
  dbDateFromPreferredServiceDate,
  thirtyDaysAgo,
} from '@backend/src/lib/date';
import { EstimateFragmentFragment, QuotePreviewQuery } from 'graphql/compiled';

// @TODO: Pull these from the backend

/**
 * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
 * @return {EstimateBuilding} The from building.
 */
// export const estimateFromBuilding = (
//   estimate: EstimateFragmentFragment | null | undefined,
// ): EstimateBuilding | undefined => {
//   const buildings = get(estimate, 'inventory.buildings', []);
//   return buildings.length ? buildings[0] : undefined;
// };

// export const estimateHasRooms = (
//   estimate: EstimateFragmentFragment | null | undefined,
// ): boolean => {
//   const fromBuilding = estimateFromBuilding(estimate);
//   return !!(
//     fromBuilding &&
//     fromBuilding.rooms &&
//     fromBuilding.rooms.length > 0
//   );
// };

/**
 * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
 * @return {Address} The from address.
 */
// export const estimateFromAddress = (
//   estimate: EstimateFragmentFragment | null | undefined,
// ): Address | undefined => {
//   const addresses = get(estimate, 'addresses', []) || [];
//   return addresses.length > 0 && addresses[0] ? addresses[0] : undefined;
// };

/**
 * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
 * @return {Address} The to address.
//  */
// export const estimateToAddress = (
//   estimate: EstimateFragmentFragment | null | undefined,
// ): Address | undefined => {
//   const addresses = get(estimate, 'addresses', []);
//   if (!addresses || !addresses.length) {
//     return undefined;
//   }
//   const lastIndex = addresses.length - 1;
//   return addresses[lastIndex] ? addresses[lastIndex] : undefined;
// };

// /**
//  * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
//  * @return {EstimateBuilding} The to building.
//  */
// export const estimateToBuilding = (
//   estimate: EstimateFragmentFragment | null | undefined,
// ): EstimateBuilding | undefined => {
//   const buildings = get(estimate, 'inventory.buildings', []);
//   const lastIndex = buildings.length - 1;
//   return buildings.length ? buildings[lastIndex] : undefined;
// };

export const estimateIsPending = ({
  estimate,
  estimateAction,
}: {
  estimate?: Maybe<Pick<EstimateFragmentFragment, 'isBooked'>>;
  estimateAction: string | null | undefined;
}): boolean =>
  !!estimate && ['book'].includes(estimateAction || '') && !estimate.isBooked;

export const estimateIsQuestion = ({
  estimateAction,
}: {
  estimateAction: string | null | undefined;
}): boolean => ['question'].includes(estimateAction || '');

export const estimateIsCanceled = ({
  estimate,
  estimateAction,
}: {
  estimate?: {
    status?: string | null | undefined;
  };
  estimateAction?: string | null | undefined;
}): boolean =>
  !!estimate &&
  (['cancel'].includes(estimateAction || '') ||
    ['No Sale'].includes(estimate.status || ''));

export const estimateIsExpired = ({
  estimate,
  estimateAction,
}: {
  estimate?: Maybe<Pick<EstimateFragmentFragment, 'preferredServiceDate'>>;
  estimateAction: string | null | undefined;
}): boolean =>
  !!estimate &&
  !['question'].includes(estimateAction || '') &&
  isBefore(
    dateFromDB(
      dbDateFromPreferredServiceDate(estimate.preferredServiceDate) || '',
    ),
    new Date(),
  );

export const estimateBookingStatusDisplay = ({
  estimate,
  estimateAction,
  t,
}: {
  estimateAction: string | null | undefined;
  estimate?: Pick<EstimateFragmentFragment, 'isBooked'>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t(x: any): any;
}): string => {
  if (estimateIsPending({ estimate, estimateAction })) {
    return t(`Pending`);
  }

  return estimate && estimate.isBooked ? t(`Booked`) : t(`Not Booked`);
};

// export const estimateStatusDisplay = ({
//   estimate,
//   estimateAction,
//   i18n,
//   startedLead,
// }) => {
//   if (estimate.id) {
//     if (estimateAction) {
//       if (estimateAction === 'book') {
//         return t(`Active`);
//       }

//       if (estimateAction === 'cancel') {
//         return t(`Canceled`);
//       }

//       return t(`Pending`);
//     }

//     if (estimateIsExpired({ estimate })) {
//       return t(`Expired`);
//     }

//     const options = {
//       Open: t(`Active`),
//       Revised: t(`Active`),
//       Booked: t(`Active`),
//       'No Sale': t(`Canceled`),
//     };

//     return options[estimate.status];
//   }

//   if (startedLead) {
//     return t(`In Progress`);
//   }

//   return t(`Not Started`);
// };

// export const estimateUseHourlyRate = () => false;

export const filterRecentEstimates = (
  estimates: EstimateFragmentFragment[],
): EstimateFragmentFragment[] =>
  estimates.filter((e) =>
    isAfter(
      dateFromDB(dbDateFromPreferredServiceDate(e.preferredServiceDate) || ''),
      thirtyDaysAgo,
    ),
  );

export const filterRelevantEstimates = (
  estimates: EstimateFragmentFragment[],
): EstimateFragmentFragment[] =>
  filterRecentEstimates(estimates).filter((e) => `${e.status}` !== 'No Sale');

export const sortEstimatesByDate = (
  estimates: EstimateFragmentFragment[],
): EstimateFragmentFragment[] =>
  estimates.slice().sort((a, b) => {
    const dateA = a.preferredServiceDate
      ? new Date(a.preferredServiceDate)
      : new Date();
    const dateB = b.preferredServiceDate
      ? new Date(b.preferredServiceDate)
      : new Date();

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });

// export const getEstimateFromRegion = (
//   estimate: EstimateFragmentFragment,
// ): Address['region'] | undefined => {
//   const fromAddress = estimateFromAddress(estimate);
//   return fromAddress ? fromAddress.region : undefined;
// };

export type QuotePreviewQueryEstimate = NonNullable<
  NonNullable<QuotePreviewQuery>['getEstimateById']
>;

// export const getRealAddressesFromQuotePreviewQueryEstimate = (
//   estimate: QuotePreviewQueryEstimate,
// ): EstimateAddress[] => {
//   // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
//   return (estimate.addresses?.filter((a) => a) as EstimateAddress[]) || [];
// };
