/**
 * Removes all non-digit characters from a phone number string.
 *
 * @param phone - The phone number string to be processed.
 * @returns A string containing only the digits from the input phone number.
 */
export const rawPhone = (phone: string): string => phone.replace(/\D/g, '');

/**
 * Formats a phone number string to the E.164 format with a country code of +1.
 *
 * @param originalPhone - The original phone number string to be formatted.
 * @returns A string formatted to the E.164 standard with a +1 country code.
 *          If the original phone number starts with '1', it is removed before adding the +1 country code.
 *          If the input is an empty string or null, it returns an empty string.
 */
export const formatBackendPhone = (originalPhone: string): string => {
  const phone = originalPhone ? rawPhone(originalPhone) : '';

  return phone ? `+1${phone.startsWith('1') ? phone.slice(1) : phone}` : phone;
};

/**
 * Formats a phone number string to the standard (XXX) XXX-XXXX format.
 *
 * @param originalPhone - The original phone number string to be formatted.
 * @returns A string formatted to the (XXX) XXX-XXXX standard.
 *          If the input is an empty string or null, it returns an empty string.
 */
export const formatPhone = (originalPhone: string): string => {
  const phone = originalPhone ? rawPhone(originalPhone) : '';

  return phone.length
    ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
    : phone;
};
