import { GetAccountEstimatesDocument } from 'graphql/compiled';
import logger from './logger';
import client from '../graphql/client';

/**
 * @typedef {import("../graphql/compiled").EstimateFragmentFragment} EstimateFragmentFragment
 * @typedef {import("../graphql/compiled").EstimateBuilding} EstimateBuilding
 * @typedef {import("../graphql/compiled").Address} Address
 */

export const getEstimates = async () => {
  logger.info('getEstimates');

  const response = await client.query({
    query: GetAccountEstimatesDocument,
    fetchPolicy: 'no-cache',
  });

  return response.data.getAccount.estimates;
};
